import React from "react";
import { Link } from "gatsby";
import * as classes from "./index.module.less";
import TOC from "components/shared/TOC";

export default ({ 
  children, 
  items, 
  title, 
  tocData = null,
  titleLevel="" }) => {
  let titleRender = ""
  if (titleLevel=="") {
    titleRender = <h4>{title}</h4>;
  } else if(titleLevel=="h3") {
    titleRender = <h3>{title}</h3>;
  } else if(titleLevel=="h2") {
    titleRender = <h2>{title}</h2>;
  }
  
  return items?.length ? (
      <div className={classes.container}>
        <div className={classes.children}>{children}</div>
        <div className={classes.side}>
  
          {tocData && <TOC toc={tocData} />}

          {titleRender}
  
          {items?.map((it, key) => (
            <Link to={it.url} key={key}>
              {it.title || it.node?.title}
            </Link>
          ))}
        </div>
      </div>
    ) : (
      children
    );

}

