import baiduapp from "images/chinese-apps/baidu.png";
import gaode_maps from "images/chinese-apps/gaode-maps.png";
import iqiyi from "images/chinese-apps/iqiyi.png";
import baidu_map from "images/chinese-apps/baidu_map.png";
import pinduoduo from "images/chinese-apps/pinduoduo.png";
import quick_worker from "images/chinese-apps/quick_worker.png";
import tencent_news from "images/chinese-apps/tencent_news.png";
import qqapp from "images/chinese-apps/qq.png";
import sogou_input from "images/chinese-apps/sogou-input.png";
import taobao from "images/chinese-apps/taobao.png";
import wechat from "images/chinese-apps/wechat.png";
import weibo from "images/chinese-apps/weibo.png";
import wifi_master_key from "images/chinese-apps/wifi-master-key.png";
import alipay_app from "images/chinese-apps/alipay_app.png";
import qqbrowser_app from "images/chinese-apps/qqbrowser_app.png";
import tencent_video_app from "images/chinese-apps/tencent_video_app.png";
import tencentmyapp_app from "images/chinese-apps/tencentmyapp_app.png";
import youku_app from "images/chinese-apps/youku_app.png";
import toutiao from "images/chinese-apps/toutiao.png";
import tiktok from "images/chinese-apps/tiktok.png";
import jingdong from "images/chinese-apps/jingdong.png";
import tencent_mm_app from "images/chinese-apps/tencent_mm_app.png";
import kugou from "images/chinese-apps/kugou.png";
import qq_music from "images/chinese-apps/qq-music.png";
import uc_browser from "images/chinese-apps/uc-browser.png";
import nail from "images/chinese-apps/nail.png";
import huawei_app from "images/chinese-apps/huawei_app.png";
import kuwo from "images/chinese-apps/kuwo.png";
import xunfei from "images/chinese-apps/xunfei.png";
import oppo from "images/stores/oppo.png";
import mango_tv from "images/chinese-apps/mango_tv.png";
import bilibili from "images/chinese-apps/bilibili.png";
import xiqua from "images/chinese-apps/xiqua.png";
import kuaishou from "images/chinese-apps/kuaishou-light.png";
import red_book from "images/chinese-apps/little-red-book.png";


const raw_apps_data = [
{details: ['Chinese Name: 微信',
'Parent Company: Tencent',
'App Category: Social/Networking'],
googleplay_link: 'https://play.google.com/store/apps/details?id=com.tencent.mm',
image: wechat,
mau: '1,012,402,800',
mau_change: "+0.35%",
text: 'WeChat'},
{details: ['Chinese Name: 支付宝',
'Parent Company: Alibaba',
'App Category: Payment/Lifestyle'],
googleplay_link: 'https://play.google.com/store/apps/details?id=com.eg.android.AlipayGphone',
image: alipay_app,
mau: '900,978,700',
mau_change: "+0.82%",
text: 'Alipay'},
{details: ['Chinese Name: 淘宝',
'Parent Company: Alibaba',
'App Category: Shopping'],
googleplay_link: 'https://play.google.com/store/apps/details?id=com.taobao.taobao',
image: taobao,
mau: '795,170,200',
mau_change: "+1.76%",
text: 'Taobao'},
{details: ['Chinese Name: 拼多多',
'Parent Company: Shanghai Xunmeng Information Technology',
'App Category: Social E-commerce'],
googleplay_link: '',
image: pinduoduo,
mau: '728,164,600',
mau_change: "-1.78%",
text: 'Pinduoduo'},
{details: ['Chinese Name: 抖音',
'Parent Company: ByteDance',
'App Category: Short Video Platform'],
googleplay_link: '',
image: tiktok,
mau: '694,874,300',
mau_change: "+0.41%",
text: 'Tiktok'},
{details: ['Chinese Name: QQ',
'Parent Company: Tencent',
'App Category: Social Networking'],
googleplay_link: 'https://play.google.com/store/apps/details?id=com.tencent.mobileqq&hl=en',
image: qqapp,
mau: '583,213,100',
mau_change: "-0.32%",
text: 'QQ'},
{details: ['Chinese Name: 百度地图',
'Parent Company: Baidu',
'App Category: Navigation'],
googleplay_link: 'https://play.google.com/store/apps/details?id=com.baidu.BaiduMap',
image: baidu_map,
mau: '538,803,900',
mau_change: "+2.44%",
text: 'Baidu Map'},
{details: ['Chinese Name: 百度',
'Parent Company: Baidu',
'App Category: Information'],
googleplay_link: 'https://play.google.com/store/apps/details?id=com.baidu.searchbox',
image: baiduapp,
mau: '490,553,200',
mau_change: "-0.9%",
text: 'Baidu'},
{details: ['Chinese Name: 快手',
'Parent Company: Beijing Kuaishou Technology',
'App Category: Short Video Platform'],
googleplay_link: 'https://play.google.com/store/apps/details?id=com.kwai.video',
image: quick_worker,
mau: '480,258,500',
mau_change: "+3.14%",
text: 'Kuaishou'},
{details: ['Chinese Name: 爱奇艺',
'Parent Company: Baidu',
'App Category: Video Streaming'],
googleplay_link: '',
image: iqiyi,
mau: '470,518,400',
mau_change: "-6.86%",
text: 'iQIYI'},
{details: ['Chinese Name: 微博',
'Parent Company: Sina',
'App Category: Social'],
googleplay_link: 'https://play.google.com/store/apps/details?id=com.sina.weibo',
image: weibo,
mau: '456,843,500',
mau_change: "-1.67%",
text: 'Weibo'},
{details: ['Chinese Name: 搜狗输入法',
'Parent Company: Sogou',
'App Category: Chinese Keyboard'],
googleplay_link: 'https://play.google.com/store/apps/details?id=com.sogou.zhuyininput',
image: sogou_input,
mau: '456,453,200',
mau_change: "+0.36%",
text: 'Sogou Keyboard'},
{details: ['Chinese Name: 高德地图',
'Parent Company: Alibaba',
'App Category: Navigation'],
googleplay_link: '',
image: gaode_maps,
mau: '454,718,200',
mau_change: "+4.15%",
text: 'Auto Navi Map'},
{details: ['Chinese Name: 华为应用市场',
'Parent Company: Huawei',
'App Category: App Store'],
googleplay_link: '',
image: huawei_app,
mau: '421,788,400',
mau_change: "+0.51%",
text: 'HUAWEI AppGallery'},
{details: ['Chinese Name: 腾讯视频',
'Parent Company: Tencent',
'App Category: Video Streaming'],
googleplay_link: 'https://play.google.com/store/apps/details?id=com.tencent.qqlive',
image: tencent_video_app,
mau: '413,219,700',
mau_change: "-3.88%",
text: 'Tencent Video'},
{details: ['Chinese Name: 京东',
'Parent Company: Jingdong',
'App Category: E-commerce'],
googleplay_link: '',
image: jingdong,
mau: '311,315,000',
mau_change: "+3.76%",
text: 'JD'},
{details: ['Chinese Name: 快手极速版',
'Parent Company: Beijing Kuaishou Technology',
'App Category: Short Video Platform'],
image: kuaishou,
mau: '291,355,000',
mau_change: "+4.29%",
text: 'Kuaishou Light'},
{details: ['Chinese Name: 今日头条',
'Parent Company: ByteDance',
'App Category: Information'],
googleplay_link: 'https://play.google.com/store/apps/details?id=com.ss.android.article.news',
image: toutiao,
mau: '290,317,300',
mau_change: "-1.69%",
text: 'Jinritoutiao'},
{details: ['Chinese Name: QQ浏览器',
'Parent Company: Tencent',
'App Category: Utilities'],
googleplay_link: '',
image: qqbrowser_app,
mau: '264,845,400',
mau_change: "-0.2%",
text: 'QQ Browser'},
{details: ['Chinese Name: 酷狗音乐',
'Parent Company: Guangzhou Kugou Computer Technology',
'App Category: Music'],
googleplay_link: '',
image: kugou,
mau: '232,416,400',
mau_change: "+0.93%",
text: 'Kugou Music'},
{details: ['Chinese Name: UC浏览器',
'Parent Company: Guangzhou Dongjing Computer Technology',
'App Category: Utilities'],
googleplay_link: 'https://play.google.com/store/apps/details?id=com.UCMobile.intl',
image: uc_browser,
mau: '229,431,200',
mau_change: "+0.21%",
text: 'UC Browser'},
{details: ['Chinese Name: QQ音乐',
'Parent Company: Tencent',
'App Category: Music'],
googleplay_link: 'https://play.google.com/store/apps/details?id=com.tencent.qqmusic',
image: qq_music,
mau: '229,326,300',
mau_change: "+0.7%",
text: 'QQ Music'},
{details: ['Chinese Name: 芒果TV',
'Parent Company: Hunan Broadcasting System',
'App Category: Video Streaming'],
googleplay_link: '',
image: mango_tv,
mau: '228,946,600',
mau_change: "+18.98%",
text: 'Mango TV'},
{details: ['Chinese Name: WiFi万能钥匙',
'Parent Company: Linksure',
'App Category: System Tools'],
googleplay_link: '',
image: wifi_master_key,
mau: '222,925,300',
mau_change: "+0.52%",
text: 'Wifi Master Key'},
{details: ['Chinese Name: 哔哩哔哩',
'Parent Company: Shanghai Kuanyu Digital Technology',
'App Category: Video Sharing'],
googleplay_link: '',
image: bilibili,
mau: '199,043,100',
mau_change: "+1.89%",
text: 'bilibili'},
{details: ['Chinese Name: 酷我音乐',
'Parent Company: Beijing Kuwo Technology',
'App Category: Music'],
googleplay_link: '',
image: kuwo,
mau: '189,147,700',
mau_change: "-9.6%",
text: 'Kuwo Music'},
{details: ['Chinese Name: 小红书',
'Parent Company: ？',
'App Category: Social'],
image: red_book,
mau: '188,814,200',
mau_change: "+1.05%",
text: 'Little Red Book'},
{details: ['Chinese Name: OPPO软件商店',
'Parent Company: Dongguan Yongsheng Communication Technology',
'App Category: App Store'],
googleplay_link: '',
image: oppo,
mau: '187,432,200',
mau_change: "+1.69%",
text: 'OPPO App Market'},
{details: ['Chinese Name: 腾讯新闻',
'Parent Company: Tencent',
'App Category: Information'],
googleplay_link: '',
image: tencent_news,
mau: '184,704,500',
mau_change: "-2.78%",
text: 'Tecent News'},
{details: ['Chinese Name: 西瓜视频',
'Parent Company: ByteDance',
'App Category: Video Sharing'],
googleplay_link: '',
image: xiqua,
mau: '183,219,100',
mau_change: "-7.67%",
text: 'Xigua Video'}
];

export const APPS = raw_apps_data.map((it, index) => ({
  ...it,
  index: index + 1,
}));
