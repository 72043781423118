import React, { useState, useEffect, Fragment } from "react";
import * as classes from "./index.module.less";
import Tooltip from "../Tooltips";

export default ({
  dataSource,
  columns,
  rowKey,
  className,
  expandedRowRender,
  responsive,
  largeRows,
}) => {
  let [rowsStatus, setRowsStatus] = useState([]);
  useEffect(() => {
    let newRowStatus = Array((!!dataSource && dataSource.length) || 0).fill(
      true
    );
    setRowsStatus(newRowStatus);
  }, [dataSource]);

  let switchStatus = (index) => {
    let newRowStatus = [...rowsStatus];
    newRowStatus[index] = !rowsStatus[index];
    setRowsStatus(newRowStatus);
  };

  return (
    <div className={classes.large}>
      <div
        className={[
          classes.container,
          responsive ? classes.responsive : "",
          largeRows ? classes.largeRows : "",
        ].join(" ")}
      >
        <table className={className}>
          <thead>
            <tr>
              {columns.map(({ title, className: c }, index) => (
                <th
                  key={index}
                  className={[c, classes.expandableButton].join(" ")}
                >
                  {title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {dataSource.map((row, index) => (
              <Fragment key={(!!rowKey && row[rowKey]) || index}>
                <tr
                  className={[
                    expandedRowRender ? classes.expandableRow : "",
                    classes.mainRow,
                  ].join(" ")}
                  onClick={() => switchStatus(index)}
                >
                  {columns.map(
                    (
                      {
                        dataIndex,
                        render,
                        className,
                        width,
                        title,
                        hideResponsiveTitle,
                      },
                      key
                    ) => (
                      <td key={key} className={className} style={{ width }}>
                        <>
                          {!hideResponsiveTitle ? (
                            <>
                              <span className={classes.responsiveHeader}>
                                {title}
                              </span>
                            </>
                          ) : null}

                          <Tooltip
                            hide={hideResponsiveTitle}
                            content={title}
                            className={
                              !hideResponsiveTitle
                                ? classes.responsiveText
                                : classes.notResponsiveText
                            }
                          >
                            <span>
                              {render
                                ? render(row[dataIndex], row)
                                : row[dataIndex]}
                            </span>
                          </Tooltip>
                        </>
                      </td>
                    )
                  )}
                </tr>
                {expandedRowRender ? (
                  <tr className={classes.rowOpen}>
                    <td colSpan={columns.length}>{expandedRowRender(row)}</td>
                  </tr>
                ) : null}
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};