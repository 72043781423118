import React from "react";
import * as classes from "./index.module.less";

export default ({ children, content, className, hide }) => {
  return (
    <div className={[classes.container, className].join(" ")}>

      <div className={classes.content}>
        {children}
      </div>

      {!hide ? <div className={classes.tooltip}>
        {content}
      </div> : null}

    </div>
  )

}