import React from "react";
import RelatedContent from "components/shared/RelatedContent";

export default ({ children }) => (
  <RelatedContent
    title="Publish Your App In China"
    titleLevel="h2"
    items={[
      {
        url: "/services/distribution/",
        title: "Publish Your App In China Today",
      },
      {
        url:
          "/blog/the-ultimate-guide-to-succeeding-in-the-chinese-app-market/",
        title: "The Ultimate Guide to Succeeding In The Chinese App Market",
      },
      {
        url: "/blog/problematic-in-china/",
        title:
          "If Your App Is Already Posted In China, That Could Be A Problem",
      },
    ]}
  >
    {children}
  </RelatedContent>
);
